body {
    margin: 0;
    display: flex;
}

.navbar {
    display: flex;
    flex-direction: row;
}

.banner {
    background-color: grey;
}
